import React,{createRef, useState, useEffect} from 'react';
import ScrollToTopBtn from './components/Button/ScrollToTopBtn.js';
import styled, { css } from 'styled-components';
// import Home from './Jasper/landing-page-demo.js';
import { HashRouter as Router, Route, Link } from "react-router-dom";
import Placeholder from './assets/img/wyndham/Placeholder.png';
import video from './assets/img/wyndham/interaction.mp4';

import UncontrolledExample from './components/Carousel/carousel.js'; 

// const Div = styled.div`
//   	--color-header: #191919 !important; 
//     --color-text: #323232 !important;
//     --color-text-dark: #666666 !important;
//     --color-neutral-light: #405379 !important;
//     --color-main-light:#ccc !important;
//     --color-main-medium:#444 !important;
//     --color-main-dark:#ccc !important;
//     --color-black: black !important;
//     --color-gray-light: #eee !important;
//     --color-gray-dark: #222 !important;
//     --color-white: white !important;
//     --color-highlight: #0F2C2F !important;
//     --gradient: linear-gradient(90deg, #ee6352, purple, #ee6352) !important;
//     --header-height:3rem !important;
//     background-color: #fff;
// `;
const H2 = styled.h4`
	opacity: 0.2;
	// margin: 0 0 -100px 0;
	font-weight: 800;
    font-size: 4em;
    color: #fff;
    // margin-top: 80px;
    line-height: 100%;
    margin-bottom: 0;
    @media (max-width: 992px) {
	    font-size: 5em;
	    
	}
`;
const OutcomeOverview = styled.div`
	width: 100%;
	height: auto;
	min-height: 100vh;
	background-color: #000;
	color: var(--color-white);
	// padding: 0 5rem;
	:nth-child(2n+1) {
		background-color: #222;
	}
	:nth-child(2n+1) h3,
	:nth-child(2n+1) .description h1,
	:nth-child(2n+1) .description p {
		color: #fff;
	}
	:nth-child(2n+1) .description .tag {
		background-color: #fff;
		color: var(--color-text);
    }
    .description {
    	display: flex;
    	flex-direction: column;
    	justify-content: center;
    	padding: 1rem 2rem;
    	padding-right: 0;
    	
    }
    .description.onleft {
    	padding-right: 2rem;
    	padding-left: 0;
    }
    .description h1 {
    	font-size: 32px;
    	font-weight: 300;
    	color: var(--color-text);
    }
    .description p .emoji {
    	font-size: 160%;
    	vertical-align:middle;
    }
    .description p {
    	margin-top: 0;
    	font-size: 120%;
    }
    .description strong {
    	color: #2B90FF;
    	font-weight: bold;
    }
    .description .tag {
    	font-size: 14px;
	    letter-spacing: .5px;
	    margin: 10px 0;
    }
    .image {

		display: flex;
	    align-items: center;
	    justify-content: center;
	    position: relative;
	    box-sizing: border-box;
	}
	.image::before {
	    content: '';
	    display: block;
	    padding-top: 60%;
	}
	.image img {
    	position: absolute;
		height: 100%;
	}
	.image video {
    	position: absolute;
		height: 100%;
	}
	.image img.widthfixed {
		width: 100%;
		height: auto;
		border: 8px solid #111;
		border-radius: 4px;
	}
	.image video.widthfixed {
		width: 100%;
		height: auto;
		border: 8px solid #111;
		border-radius: 4px;
	}
	h4 {
		margin: 0;
	}
`;
const DesignGoal = styled.div`
	<!-- display: flex; -->
	flex-direction: column;
	align-items: start;
	padding: 0 10px 0 0;
	height: 100%;
	>* {
		margin: 0 auto;
	}
	>img {
		display: inline-block;
		position: relative;
		width: 100%;
		height: auto;
	}
	>img:before {
		position: absolute:
		display: block;
		width: 20px;
		height: 20px;
		border-radius: 30px;
		
	}
	>div:last-child {
    padding-right: 0;
    margin-right: 0;
    background-color: red;
  }
	>h1 {
		width: 100%;
		margin: 0;
		color: #0079FF;
		font-size: 4.5em;
		margin-bottom: 10px;
		<!-- text-align: center; -->
	}
	>h3 {
		margin: 20px 0;
		font-weight: 600;
	}
	>p {
		padding-right: 10px;
		margin-bottom: 40px;
	}
`
const Dot = styled.div`
	height: 12px;
	width: 12px;
	background-color: #FFCC46;
	border-radius: 50%;
	display: inline-block;
	margin: 0 8px;
	margin-left: -20px;
`;
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { return images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('./assets/img/fanduel/', false, /\.(png|jpe?g|svg|gif)$/));

const controls = [
		{ 
	    	src: images['token-1.png'],
	    	class: ''
		},
		{ 
	    	src: images['token-2.png'],
	    	class: ''
		}
];
const Wyndham = (props) => {
	useEffect(()=>{
		const faders = document.querySelectorAll(".section-box");

		const appearOptions = {
			threshold: 0,
			rootMargin: "0px 0px -150px 0px"
		};

		const appearOnScroll = new IntersectionObserver(function(
			entries,
			appearOnScroll
		) {
			entries.forEach(entry => {

				if (!entry.isIntersecting) {
					return;
				} else {
					entry.target.classList.add("appear");
					entry.target.classList.add("animate__animated");
					entry.target.classList.add("animate__fadeInUp");
					appearOnScroll.unobserve(entry.target);
				}
			});
		},
		appearOptions);

		faders.forEach(fader => {
			appearOnScroll.observe(fader);
		});



	},[])
	

		return (
		<div className="flex nasa" style={{position: "relative", top: "80px"}}>
			<portfolio-intro className="flex">
				
				<div className="hero-img-box">
					<img src={images['cover3.png']} style={{backgroundColor: '#222', height:"auto", width:"100%"}} alt="" />
				</div>
				
			</portfolio-intro>

			<div className="portfolio-content flex-width-70">
				<h1>Component Upgrader Plugin</h1>
				<div className="section-box brief">
					<div className="grid grid-gap-xs">
						<div className="col-xl-4 col-md-4 col-xs-6 col-6">
							<h4>My Role</h4>
							<ul>
								<li>Product Designer</li>
								<li>UX Engineer</li>
							</ul>
						</div>
						<div className="col-xl-4 col-md-4 col-xs-6 col-6">
							<h4>Duration</h4>
							<ul>
								<li>Sep - Dec 2024</li>
							</ul>
							
						</div>
						
						
						<div className="col-xl-4 col-md-4 col-xs-6 col-6">
							<h4>Deliverables</h4>
							<ul>
								<li>Figma Plugin</li>
								<li>Figma Playground File</li>
								<li>Workshops</li>
							</ul>
							
						</div>
					</div>	
				</div>
				
				<div className="section-box">
					<h3 className="section-title">Overview</h3>
					{/*Plugin Development for Automated Component Upgrade */}	
					<p>While working on a large-scale design system, I identified a recurring issue: the manual process of replacing deprecated components with updated ones. This process was time-consuming, error-prone, and required designers to manually adjust variants and overrides for each component. It often led to inconsistencies in design files and slowed down the adoption of the latest components across the organization. So I developed a Figma plugin that automates upgrading deprecated components with a single click. </p>
				</div>
				
				<div className="section-box">
					<h3 className="section-title">Impact</h3>
					<p>The component upgrader plugin significantly improved efficiency, consistency, and scalability within the design system.</p>
					<div className="grid">
					{
		              [{ 
		                  title: "",
		                  number: "20hrs",
		                  description: "per week saved by cutting component replacement time from 2 minutes to 2 seconds across 10 designers."
		              },
		              { 
		                  title: "",
		                  number: "50%",
		                  description: "fewer component-related inconsistencies by eliminating variant-selection errors and preserving overrides."
		              },
		              { 
		                  title: "",
		                  number: "100+",
		                  description: "design files updated in one seamless rollout of new components."
		              }
		            ].map((item,i) => (
		              <DesignGoal key = {i} className="col-xl-4 col-md-4 col-xs-4 col-12">
											<h3>{item.title}</h3>
											<h1>{item.number}</h1>
											<p>{item.description}</p>
		              </DesignGoal>
		            ))}
		          
					</div>
				</div>
				

				
{/*----------------------------------- The Solution -----------------------------------*/}				
				<div className="section-box">
					<h3 className="section-title">My Solution</h3>	
					<p>I developed a custom plugin to automate the component replacement process. The plugin can do:</p>
					<div className="grid" >
					{
		              [{ 
		                  title: "✔️ Variant Matching",
		                  img: images['correctupdate.png'],
		                  description: "Our new components now use a boolean property to show or hide elements. The plugin automatically replaces deprecated components with updated ones, and sets the correct boolean state - no manual adjustments needed"
		              },
		              { 
		                  title: "✔️ Preserve Original Design",
		                  img: images['correctvariant.png'],
		                  description: "Applies the appropriate variants and overrides based on the context of the design."
		              },
		              { 
		                  title: "✔️ Preserve the Overrides",
		                  img: images['correctoverrides.png'],
		                  description: "Preserves existing overrides, ensuring no loss of customized styles or data."
		              }
		            ].map((item,i) => (
		              <DesignGoal key = {i} className="col-12">
		              		<h3>{item.title}</h3>
											<p>{item.description}</p>
		                	<img src={item.img} alt="" />
		              </DesignGoal>
		            ))}
					</div>

				</div>




			
				{/*<div className="section-box">
					<h3 className="section-title">The Solution</h3>
					<p>I started the redesign process by conducting an audit of the current design system and design files in Abstract and Sketch. I gathered the thoughts from the designers on UX Team and the developers who implement the design.</p>
					<p>At the same time, I started to build the styles that design tokens in Figma. Playing around with the components and transferring design files from Sketch to Figma. Then I start to notice the challenges we are facing.</p>
				</div>*/}


			</div>

	{/*		<div className="section-box fullscreen-container flex flex-center">

				<OutcomeOverview className="grid" style={{"min-height": "100vh"}}>
					<div className="col-1">
					</div>
				
					<div className="image col-xl-7 col-md-7 col-xs-12 col-12">
						<p>Automation is critical for scaling design system adoption in large organizations.
						Identifying and addressing pain points within the workflow can lead to impactful solutions that save time and increase consistency.
						Collaboration with cross-functional teams (developers, QA, and PMs) ensures the plugin aligns with both design and development needs.
						This project highlights my ability to not only identify systemic issues but also deliver innovative tools that drive efficiency and consistency at scale.</p>
					</div>
				</OutcomeOverview>

			</div>

		*/}	
{/*----------------------------------- The Solution -----------------------------------*/}	

			<div className="section-box fullscreen-container flex flex-center">

				<OutcomeOverview className="grid">
					<div className="col-1">

					</div>
					<div className="image col-xl-5 col-md-5 col-xs-12 col-12">
						<h2 style={{position: "absolute", left: "5px", top: "5%"}}>Before</h2>
						<img className="widthfixed" src={images['oldreplacement.gif']} alt="" />
					</div>
					<div className="description col-xl-5 col-md-5 col-xs-12 col-12">
						<h1><span className="emoji">😶‍🌫️ </span>Manual Replacement</h1>
						<p> <strong>Time-consuming:</strong> Required extensive manual work.</p>
						<p> <strong>Manual Adjustments:</strong> Needed to adjust variants and overrides by hand.</p>
						<p> <strong>Error-prone:</strong> Increased the risk of mistakes. Led to design inconsistencies</p>
						<p> <strong>Slowed down the adoption</strong></p>
					</div>
					<div className="col-1">
					</div>
				</OutcomeOverview>

				<OutcomeOverview className="grid">
					
					<div className="col-1">
					</div>
					<div className="image col-xl-5 col-md-5 col-xs-12 col-12">
						<h2 style={{position: "absolute", left: "5px", top: "5%"}}>After</h2>
						<img className="widthfixed" src={images['newreplacement.gif']} alt="" />
					</div>
					<div className="description col-xl-5 col-md-5 col-xs-12 col-12">
						<h1><span className="emoji">🥳 </span>Automated Replacement</h1>
						<p> <strong>Automatic Replacement</strong> Replaces deprecated components</p>
						<p> <strong>Contextual Variants:</strong> Applies appropriate variants</p>
						<p> <strong>Preserves Overrides:</strong> Retains customized styles</p>
						<p> <strong>Time Efficiency:</strong> Reduces update effort</p>
					</div>
				</OutcomeOverview>

		

				<OutcomeOverview className="grid" style={{"min-height": "100vh"}}>
					<div className="col-1">
					</div>
					<div className="description onleft col-xl-3 col-md-3 col-xs-12 col-12">
						<h1> Component Updates with One Click</h1>
						<p>Instantly upgrade outdated components to new, flexible versions with a single click. The plugin applies the correct variants, preserves overrides, and gives you control over the update scope.</p>
					</div>
					<div className="image col-xl-7 col-md-7 col-xs-12 col-12">
						<img className="widthfixed" src={images['plugin.gif']} alt="" />
					</div>
						
				</OutcomeOverview>

				<OutcomeOverview className="grid">
					<div className="col-1"></div>
					<div className="description onleft col-xl-3 col-md-3 col-xs-12 col-12">
						<h1>Figma Playground File </h1>
						<p>I created a Figma playground file for exploring the plugin, along with a step-by-step guide to help users upgrade their design files.</p>
					</div>
					<div className="image col-xl-7 col-md-7 col-xs-12 col-12">
						<img className="widthfixed" src={images['playgroundfile.png']} alt=""  style={{"border": "0px"}} />
					</div>
					
				</OutcomeOverview>

				<OutcomeOverview className="grid">
					<div className="col-1"></div>
					<div className="description onleft col-xl-3 col-md-3 col-xs-12 col-12">
						<h1>Training Session: Streamline Upgrades and Boost Adoption</h1>
						<p>I host a session to demonstrate how to upgrade deprecated components and guide teams on applying the newest foundations, components, and variable themes. This boosts design system adoption and improves efficiency, consistency, and scalability.</p>
					</div>
					<div className="image col-xl-7 col-md-7 col-xs-12 col-12">
						<img className="widthfixed" src={images['workshop.png']} alt="" />
					</div>
					

				</OutcomeOverview>

				


				




			</div>



			

			







			
			



			<ScrollToTopBtn></ScrollToTopBtn>
		</div>
		)
	
}
export default Wyndham;